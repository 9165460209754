<template>
  <swiper ref="mySwiper" :options="swiperOptions" @slideChange="sendData" @ready="sendData" >
    <div class="growing-slide swiper-slide slide-wrapper" :class="{checked: selectedBooks.filter(function(item) { return item.id == book.id }).length > 0}"
    v-for="(book, key) in props" :key="index.toString() + key">
      <swiper-slide>
        <div class="growing-slide-cover-container books-featured-slide-cover-container"
        :class="{'checked-mark': selectedBooks.filter(function(item) { return item.id == book.id }).length > 0}"
        @click="selectBook(key, book.id)">
          <div class="growing-slide-cover"
          :class="{'cover checked-cover': selectedBooks.filter(function(item) { return item.id == book.id }).length > 0}"
          >
            <img :src="plainUrl + '/img/genres/' + book.img" :alt="book.label"/>
          </div>
        </div>
      </swiper-slide>
    </div>
</swiper>
</template>
<script>
import EventBus from '../eventBus'
export default {
  name: 'OnBoardingSelectGenres',
  props: {
    props: Array,
    id: String,
    parent: [Object, Number]
  },
  data () {
    return {
      selectedBooks: [],
      slideIndex: 0,
      index: this.id,
      swiperOptions: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        observer: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 0,
          modifier: 1,
          slideShadows: false
        }
      }
    }
  },
  created () {
    if (this.id === 'new') {
      this.$https('/usergenres', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        const genres = response.data.response
        genres.forEach(e => {
          this.selectedBooks.push({
            id: e.genres_id,
            children: [],
            select: true
          })
        })
        EventBus.$emit('selected-genres', this.selectedBooks)
      })
        .catch(error => {
          console.log('', error)
        })
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    this.swiper.slideTo(0, 500, true)
  },
  watch: {
    props: function () {
      this.swiper.slideTo(0, 500, true)
      this.sendData()
    },
    id: function () {
      this.index = this.id
    },
    parent: function () {
      this.selectedBooks.push(this.parent)
    }
  },
  methods: {
    sendData () {
      this.slideIndex = this.$refs.mySwiper.$swiper.activeIndex
      var el = this.props[this.slideIndex]
      const payload = {
        parent: el.id,
        label: el.label,
        categories: el.children
      }
      EventBus.$emit('selected-books-genres', payload)
      EventBus.$emit('selected-genres', this.selectedBooks)
    },
    selectBook (key, id) {
      var arrayItem = this.props[key]
      let isSelected = false
      let toDelete = 0
      this.selectedBooks.forEach(element => {
        if (element.id === id) {
          isSelected = true
          toDelete = this.selectedBooks.indexOf(element)
          if (element.children.length) {
            element.children.forEach(item => {
              const index = this.selectedBooks.indexOf(item.id)
              this.selectedBooks.splice(index, 1)
            })
          }
        }
      })
      this.props.forEach(e => {
        if (e.id === id) {
          e.children.forEach(i => {
            this.selectedBooks.forEach(j => {
              if (i.id === j.id) {
                const index = this.selectedBooks.indexOf(j)
                this.selectedBooks.splice(index, 1)
              }
            })
          })
        }
      })
      if (isSelected === false) {
        this.selectedBooks.push({
          id: arrayItem.id,
          category: arrayItem.label,
          children: arrayItem.children,
          select: true
        })
        arrayItem.children.forEach(e => {
          this.selectedBooks.push({
            id: e.id,
            category: e.label,
            select: true
          })
        })
      } else {
        this.selectedBooks.splice(toDelete, 1)
      }
      EventBus.$emit('selected-genres', this.selectedBooks)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
